import * as React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "react-bootstrap";
import { People } from "react-bootstrap-icons";

const Testimonials = ({ testimonials }) => (
    <Row xs={1} md={1} className="justify-content-md-center">
        <Col className="justify-content-md-center">
            {testimonials.map((testimonial, index) => (
                testimonial.name &&
                    <div key={index}>
                        <Card key={index} border="dark" className="my-3 border-1 rounded-2 card-referenz card-testimonial text-start">
                            <Card.Header className="card-testimonial-header testimonial-header">
                                <h3 className="card-testimonial-lead"><People className="bi bi-people" /> Wer benutzt es?</h3>
                                <h5 className="card-testimonial-name">{testimonial.name}</h5>
                            </Card.Header>
                                <Card.Body>
                                    <p>
                                        <strong style={{fontVariantCaps: "small-caps"}}>Betrieb Art:</strong> {testimonial.category}
                                        <div><strong style={{fontVariantCaps: "small-caps"}}>Programme im Betrieb:</strong> {testimonial.product}</div>
                                    </p>
                                    {testimonial.quote &&
                                        <div>
                                            <hr />
                                            <strong style={{fontVariantCaps: "small-caps"}}>Betrieb Quote:</strong>  {testimonial.quote}
                                        </div>
                                    }
                            </Card.Body>
                            <Card.Footer className="text-primary">{testimonial.location}</Card.Footer>
                        </Card>
                    </div>
             ))}
        </Col>
    </Row>
);

Testimonials.propTypes = {
    testimonials: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string,
            description: PropTypes.string,
            product: PropTypes.string,
            location: PropTypes.string,
            quote: PropTypes.string
        })
    ),
};

export default Testimonials;
