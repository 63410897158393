import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo";
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import { Row, Col } from "react-bootstrap";
import { Stars } from "react-bootstrap-icons";
import { differenceInSeconds, endOfDay, intlFormat, parse } from "date-fns"
// import { de } from "date-fns/locale"
import Email from "../components/Email";
import { products } from "../data/programm"

// eslint-disable-next-line
export const ProductProgramTemplate = ({
    title,
    item,
    itemNum,
    category,
    brutto,
    salePrice,
    savings,
    expires,
    updates,
    description,
    SystemReqs,
    productImage,
    image1,
    intro,
    testimonials,
    content,
    contentComponent,
}) => {
    const PageContent = contentComponent || Content;
    const pimage = getImage(productImage) || productImage;
    const alt = `gastrokalk Programm Preise`;
    const [displayPreise, preiseBewharung] = matchProductPreise() || `original sin`;

    // empty fields that have string placeholder -null
    const emptyFieldPlaeholder = null;
    const fieldValue = {isEmptyField : ''};
     
     function checkEmptyField (field) {
        fieldValue.isEmptyField = field.toLowerCase().includes(emptyFieldPlaeholder) ?  true : false;
        return fieldValue.isEmptyField
     }

     // check first for expired promotion date, then for valid sale price. Otherwise just display the displayPreise (netto) price.

    const parsedDate = parse(expires, 'dd.MM.yyyy', new Date());
    const promotion = {isExpired: ''};
    
    function checkExpirationDate (date) {
        // const eod = endOfDay(new Date(parsedDate), { locale: de });
        const diffsec = differenceInSeconds(endOfDay(new Date(parsedDate)), endOfDay(new Date()));
        promotion.isExpired = diffsec < 0 ? true : false;
        return promotion.isExpired
    }

    function matchProductPreise () {
        for (let i = 0; i < products.length; i++) {
          if (products[i].itemNumber === itemNum) {
              let displayPreise = products[i].netto,
                  preiseBewharung = products[i].bewharung;
              return [displayPreise, preiseBewharung]
          }
        }
        return 'E-Mail uns für Preise';
      }

    return (
        <>
            <Row>
                <Col lg={8} className="pb-2">
                    <h1 className="page-title title-border">
                        {title} { ! checkEmptyField(updates) && (<span className="item-red-highlight">&nbsp;{updates}<Stars className="bi bi-stars" /></span>)}
                        <div className="item-name">Art.-Nr. {item}</div>
                    </h1>
                </Col>
            </Row>

            <Row>
                <Col lg={{ span: 8, offset: 2 }} className="">
                    <div className="content">

                        <FullWidthImage img={pimage} alt={alt} />
                        
                        <Row>
                            <Col lg={4}>
                            { content && 
                            (
                                <PageContent className="program-images" content={content} />
                            )}
                               
                            </Col>
                            
                            <Col>
                                {/* <div><strong>Programmbeschreibung oder Alles Auf einen Blick</strong></div> */}
                                {description &&
                                    (
                                        <PageContent className="" content={description} />
                                    )
                                }
                                {/* {descriptionBullets && 
                                    (
                                        descriptionBullets.map((descriptionBullet, index) => (
                                            <div key={index}>{descriptionBullet}</div>
                                        )
                                        )
                                    )
                                } */}
                                {((!checkExpirationDate(expires)) &&
                                    <>
                                        {((!checkEmptyField(salePrice)) &&
                                            <>
                                                <div>
                                                    <span className="product-full-price-sale">{displayPreise}  {preiseBewharung}</span> Jetzt nur: <span className="product-sale-price item-red-highlight">{salePrice} €</span> zzgl. MwSt.
                                                    <div>Sie Sparen: <span className="item-red-highlight">{savings}</span>*</div>
                                                </div>
                                                <div className="product-sale-expire-date">*Aktion gültig bis:  {`${intlFormat(new Date(parsedDate), { year: 'numeric', month: 'long', day: 'numeric', }, { locale: 'de-DE', })}`}</div>
                                            </>
                                        )
                                            ||
                                            (<div><span className="product-full-price">{displayPreise} {preiseBewharung}</span> zzgl. MwSt.</div>)
                                        }
                                    </>
                                )
                                    ||
                                    (<div><span className="product-full-price">{displayPreise} {preiseBewharung}</span> zzgl. MwSt.</div>)
                                }

                                {SystemReqs && 
                                (
                                    <div className=""><strong>Systemanforderungen:</strong> {SystemReqs}</div>
                                )}
            
                                {/* <div className="pb-3">
                                    <div className="send-us-email">Zur bestellung</div>
                                    <div className="is-info">kontaktiere Sie uns: <a href={`mailto:gastrokalk@gmail.com?subject=${item}`}>gastrokalk@gmail.com</a></div>
                                </div> */}
                                <Email callingPage={item} source="program" />
                            </Col>
                        </Row>
                        
                        {/* <PreviewCompatibleImage imageInfo={image1} /> image1 */}
                        
                        <Row>
                            <Col className="justify-content-md-center">

                                <Features gridItems={intro.blurbs} />

                            </Col>
                        </Row>
                        
                        <Testimonials testimonials={testimonials} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

ProductProgramTemplate.propTypes = {
    title: PropTypes.string,
    item: PropTypes.string,
    itemNum: PropTypes.string,
    category: PropTypes.string,
    brutto: PropTypes.string,
    salePrice: PropTypes.string,
    savings: PropTypes.string,
    expires: PropTypes.string,
    updates: PropTypes.string,
    description: PropTypes.string,
    description2: PropTypes.string,
    description3: PropTypes.string,
    descriptionBullets: PropTypes.array,
    SystemReqs: PropTypes.string,
    productImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
    main: PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
    }),
    testimonials: PropTypes.array,
    content: PropTypes.string,
};

const ProductProgram = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const  location= "programme"

    return (
        <Layout location={location} currentPage={frontmatter.title}>
            <ProductProgramTemplate
                title={frontmatter.title}
                item={frontmatter.item}
                itemNum={frontmatter.itemNum}
                category={frontmatter.category}
                brutto={frontmatter.brutto}
                salePrice={frontmatter.salePrice}
                savings={frontmatter.savings}
                expires={frontmatter.expires}
                updates={frontmatter.updates}
                description={frontmatter.description}
                description2={frontmatter.description2}
                description3={frontmatter.description3}
                descriptionBullets={frontmatter.descriptionBullets}
                SystemReqs={frontmatter.SystemReqs}
                productImage={frontmatter.productImage}
                image1= {frontmatter.image1}
                intro={frontmatter.intro}
                main={frontmatter.main}
                testimonials={frontmatter.testimonials}
                contentComponent={HTMLContent}
                content={data.markdownRemark.html}
            />
        </Layout>
    );
};

ProductProgram.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            content: PropTypes.string,
            frontmatter: PropTypes.object,
        }),
    }),
};

export default ProductProgram;

export const Head = () => <Seo title="Gastrokalk Programs" />

export const productPageQuery = graphql`
  query ProductProgram($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        productImage {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        image1 {
          alt
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        item
        itemNum
        category
        brutto
        salePrice
        savings
        expires 
        updates
        description
        description2
        description3
        descriptionBullets
        SystemReqs
        intro {
          blurbs {
            heading
            summary
            summary1
            summary2
            summary3
            summary4
            bulletsInhaltHeading
            bulletsInhalt
            bulletsKannHeading
            bulletsKann
            Systemanforderungen
            video
          }
        }
        main {
          heading
          description
        }
        testimonials {
          name
          category
          description
          product
          location
          quote
        }
      }
    }
  }
`;
