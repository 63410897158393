import * as React from "react";
import PropTypes from "prop-types";
import { CheckCircle } from "react-bootstrap-icons";
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";

const FeatureGrid = ({ gridItems }) => (

    <>
        {gridItems.map((item, index) => (

            <div id={`programme${index}`} key={index}>

                <Row xs={1} md={1} className="justify-content-md-center mt-4">
                    <Col className="bbg-img">
                        {item.bulletsInhalt &&
                            <Card border="dark" className="mb-3 border-1 rounded-2">
                                <CardHeader className="card-header-program"><h4 className="title is-size-5 text-center">Programm Details</h4></CardHeader>
                                <CardBody>
                                    <ul style={{ textAlign: "initial", }}>
                                        {item.bulletsInhalt?.map((bullet) => (
                                            <li key={bullet}>
                                                <CheckCircle className="bi bi-check-circle" />
                                                {bullet}
                                            </li>
                                        ))}
                                    </ul>
                                </CardBody>
                            </Card>
                        }
                    </Col>
                </Row>
                {/* <Row xs={1} className="g-4 justify-content-md-center mt-4">
                    <Col>
                        {item.bulletsKann &&
                            <Card border="dark" className="mb-3 border-1 rounded-2">
                                <CardHeader className="card-header-program"><h4 className="title is-size-5">{item.bulletsKannHeading} <i>{item.heading}</i></h4></CardHeader>
                                <CardBody>
                                    <ul style={{ textAlign: "initial", }}>
                                        {item.bulletsKann?.map((bullet, index) => (
                                            <li key={bullet}><CheckCircle className="bi bi-check-circle" />{bullet}</li>
                                        ))}
                                    </ul>
                                </CardBody>
                            </Card>
                        }
                    </Col>
                </Row> */}

                <Row className="has-text-centered">
                    <Col>

                        {item.video &&
                            <>
                                {/* <div>
                                    <h5>Info video</h5>
                                    <div dangerouslySetInnerHTML={{ __html: item.video }} />
                                </div> */}
                                {/* <p className="pb-3">
                                    <div className="send-us-email text-center">Zur bestellung<br />Get it NOW!</div>
                                    <div className="is-info email-pill"> kontaktiere Sie uns: <abbr title="copy und paste email">gastrokalk@gmail.com</abbr></div>
                                </p> */}
                            </>
                        }

                        {/* {item.Systemanforderungen &&
                            <Row xs={1} md={1} className="g-4 justify-content-md-center">
                                <Col className="bullet-details">
                                    <h4 className="header-anforderung-details"><Gear className="bi bi-gear" />Systemanforderungen</h4>
                                    <hr className=" border-primary border-2 opacity-50" />
                                    <ul style={{ textAlign: "initial", }}>
                                        {item.Systemanforderungen.map((bullet) => (
                                            <li key={bullet}><FiletypeXlsx className="bi bi-filetype-xlsx" />{bullet}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        } */}
                    </Col>

                </Row>
            </div>
        ))}
    </>
);

FeatureGrid.propTypes = {
    heading: PropTypes.string,
    gridItems: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            video: PropTypes.string,
            price: PropTypes.string,
            salePrice: PropTypes.string,
            savings: PropTypes.string,
            expires: PropTypes.string,
            bulletsInhaltHeading: PropTypes.string,
            bulletsInhalt: PropTypes.array,
            bulletsKannHeading: PropTypes.string,
            bulletsKann: PropTypes.array,
            Systemanforderungen: PropTypes.array,
        })
    ),
};

export default FeatureGrid;
